@import 'variables.scss';

/*──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                                        PARTIE RESPONSIVE
/*─
/*──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Max 1350
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/


@media screen and (max-width:1350px) {
    .dropdown {
        .itineraires_dropdown {
            .wrapper {
                flex-direction: column;
                align-items: start;

                .typeandplace {
                    width: 100%;
                    margin-bottom: 15px;

                    .wrapper-typeandplace:last-child {
                        margin-right: 0 !important;
                    }
                }

                .specificites {
                    width: 100%;
                }
            }
        }
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      More than 1200
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

@media screen and (min-width:1201px) {
    .routes {
        .col-xl-4 {
            .wrapper-tour {
                .renseignements {
                    div {
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Max 1200
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

@media screen and (max-width:1200px) {
    .dropdown {
        .routes {
            .col-xl-4 {
                padding-left: 0 !important;
                padding-right: 0 !important;

                .wrapper-tour {
                    .renseignements {
                        div {
                            padding: 0 40px;
                        }
                    }
                }
            }
        }
    }

    /*─────────────────────────────────────────────────────*/
    /*─────────────────────────────────────────────────────*/
    /*─
/*─                                     Page : Article
/*─
/*─────────────────────────────────────────────────────*/
    /*─────────────────────────────────────────────────────*/

    section.blog {
        .content {
            h1 {
                font-size: 44px;
            }
        }

        .title {
            h2 {
                font-size: 42px;
            }

            div.text {
                p {
                    font-size: 22px;
                }
            }
        }
    }

    /*─────────────────────────────────────────────────────*/
    /*─────────────────────────────────────────────────────*/
    /*─
/*─                                     Page : A propos
/*─
/*─────────────────────────────────────────────────────*/
    /*─────────────────────────────────────────────────────*/

    section.apropos {
        .title {
            .quisommesnous {
                .leftimage {
                    margin-bottom: 50px;

                    img {
                        max-width: 450px;
                    }
                }
            }
        }
    }

    section.mosaique {
        .container {
            .articles-text {
                gap: 1rem !important;
            }
        }
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Max 992
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

@media screen and (max-width:992px) {
    header:not(.sticky) {
        border-bottom: none;
    }
    
    .liste.itineraires {
        margin-top: 0;
    }

    header {
        position: fixed;
        width: 100%;
        z-index: 9999;

        .header-wrapper {

            .header-links,
            .header-buttons {
                display: none !important;
            }

            .openmenu {
                display: block !important;
            }
        }
    }

    section.hero {

        .ken-burns-slideshow {
            .slide {
                .caracteristique {
                    justify-content: center;
                    .content {
                        .name {
                            justify-content: center;
                        }
                    }
                }
            }
        }

        .hero_content {
            .text {
                h1 {
                    font-size: 40px;
                }

                p {
                    font-size: 22px;
                }
            }
            .wrapper_tour_types {
                width: 80%;
            }
        }
    }

    section.liste {
        .dropdown {
            .itineraires_dropdown {
                .wrapper {
                    display: block;

                    .typeandplace {
                        display: block;
                        width: 100%;

                        .wrapper-typeandplace {
                            width: 100%;
                            margin-bottom: 10px;

                            .select_display {
                                .wrapper_select {
                                    margin-right: 0;
                                    padding: 10px;

                                    .wrapper-picto {
                                        padding: 0 0 0 10px;
                                    }

                                    select {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .findandinterets {
                .wrapper {
                    margin-bottom: 50px;

                    .find {
                        display: none;
                    }
                }

                .find-mobile {
                    display: block;
                }
            }

            .routes {
                .col-xl-4 {
                    .wrapper-tour {
                        .renseignements {
                            div {
                                padding: 0 25px;
                            }
                        }
                    }
                }
            }
        }
    }

    section.liste.itineraires {
        padding-top: 100px;
    }

    .dropdown {
        .itineraires_dropdown {
            .wrapper {
                .specificites {
                    .wrapper-specificites {
                        .select_display {
                            .wrapper_select {
                                margin-right: 0;
                                padding: 10px;

                                .wrapper-picto {
                                    padding: 0 0 0 10px;
                                }

                                select {
                                    padding: 0;
                                }
                            }
                        }

                        &:first-child {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    section.blog {
        .content {
            padding-top: 150px;
        }

        .news {
            .autres_guides {
                .image {
                    .littletext {
                        h2 {
                            font-size: 26px;
                        }
                    }
                }
            }
        }
    }

    section.tour_hero {
        padding-top: 100px;
    }

    section.apropos {
        .content {
            padding-top: 100px;
            height: 300px;
        }

        .title {
            .quisommesnous {
                .leftimage {
                    img {
                        max-width: 350px;
                    }
                }

                .righttext {
                    h2 {
                        font-size: 32px;
                    }

                    p {
                        font-size: 22px;
                    }
                }
            }
        }

        .diffusion {
            .outils_diffusion {
                .text-wrapper {
                    div {
                        h3 {
                            font-size: 20px;
                        }
                    }

                    p {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    section.mosaique {
        .content {
            padding-top: 100px;
            height: 300px;
        }
        .articles {
            .images {
                .leftdiv {
                    .background {
                        div {
                            h2 {
                                font-size: 24px;
                            }
                        }
                    }
                }
                .rightdiv {
                    .background:first-child {
                        div {
                            a {
                                font-size: 24px;
                            }
                        }
                    }
                    .background:last-child {
                        div {
                            a {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
    }

    section.informations {
        padding-top: 100px;
        margin-top: 0;
    }

    section.regions,
    section.mobile-regions {
        margin-top: 100px;
    }

    section.footer {
        .wrapper {
            .footer-bottom {
                flex-direction: column;
                align-items: start;

                .left-content {
                    flex-direction: column;

                    .logo {
                        margin-bottom: 20px;

                        img {
                            width: auto;
                        }
                    }

                    .content {
                        ul {
                            flex-direction: column;
                            align-items: start;

                            li {
                                &:not(:last-child) {
                                    margin-bottom: 20px;
                                }

                                &:last-child {
                                    margin-bottom: 50px;
                                }

                                span {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section.incontournables {
        .images {
            .leftdiv {
                .background {
                    div {
                        h3 {
                            font-size: 24px;
                        }
                    }
                }
            }
            .rightdiv {
                .background:first-child {
                    div {
                        h3 {
                            font-size: 24px;
                        }
                    }
                }
                .background:last-child {
                    div {
                        h3 {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }

    .erreur {
        height: 100vh;
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Max 768
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

@media screen and (max-width:768px) {

    section.hero {
        .hero_content {
            .wrapper_tour_types {
                width: 100%;
            }
        }
    }

    .tourhtml {
        overflow-x: hidden;
    }

    /** Régions **/
    section.regions {
        display: none;
    }

    section.mobile-regions {
        display: block;
    }

    /** Incontournables **/
    section.mobile-incontournables {
        display: block;
    }

    section.incontournables {
        display: none;
    }

    /** Outils **/
    section.outils {
        display: none;
    }

    section.outils_mobile {
        display: block;
    }

    div.articles {
        display: none;
    }

    section.mobile-articles {
        display: block;
    }


    section.tour_hero {
        .image {
            .title {
                h1 {
                    font-size: 38px;
                }

                h3 {
                    font-size: 28px;
                }
            }
        }
    }

    /** Image **/
    section.image {
        .background {
            .content {
                min-height: 500px;
                justify-content: center;
                align-items: center;
                padding: 0;

                h2 {
                    text-align: center;
                    margin-bottom: 30px;
                    font-size: 26px;

                }

                a {
                    font-size: 16px;
                }
            }
        }
    }

    section.liste {
        .col-lg-4.routes_map {

            .nomachanger,
            .verbier {
                display: none;
            }
        }
    }

    .mobile-openmap {
        z-index: 999;
        display: flex;
        align-items: center;
        left: 50%;
        transform: translate(-50%, 0);
        position: fixed;
        bottom: 20px;
        background-color: $vert;
        padding: 10px 20px;
        border-radius: 30px;
        box-shadow: 0px 0px 60px rgba($color: #000000, $alpha: 0.5);

        p {
            font-weight: 600;
            font-size: 16px;
            color: $fff;
            margin-right: 10px;
        }

        img {
            height: 20px;
        }

    }

    /** Footer **/
    section.footer {
        .wrapper {
            .row {
                .col-md-4 {
                    text-align: start;
                    div {
                        display: block;
                        text-align: start;
                    }
                }
            }

            .col-md-4:not(:first-child) {
                margin-top: 50px;
            }
        }
    }

    .routes_map {
        .meteo {
            .content {
                .previsions {
                    .temps {
                        div {
                            margin: 0 15px 0 0;
                        }
                    }
                }
            }
        }
    }

    section.informations.tour {
        padding-top: 100px;

        .general_text {

            .hero,
            .bigimage {
                display: none;
            }

            .mobile-tour-hero {
                display: block;
            }

            .informations {
                .wrapper {
                    .caracteristiques {
                        display: block;

                        .difficulty_type {
                            margin-bottom: 15px;
                        }
                    }

                    .logo {
                        display: none;
                    }
                }
            }

            .informations_balalde {
                .wrapper {
                    .caracteristiques {
                        flex-direction: column;
                        align-items: start;

                        .difficulty_type {
                            margin-bottom: 10px;
                        }
                    }

                    .logo {
                        display: none;
                    }
                }
            }

            .mobile-images {
                margin-bottom: 50px;
                display: block;
            }

            .first_images {
                display: none;
            }

            .mobile-gpx {
                display: block;
            }

            .second_images {
                display: none;
            }

            .video {
                width: 100vw;
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;

                video {
                    border-radius: 0;
                }

                .overlay-video {
                    p {
                        font-size: 12px;
                        padding: 10px 15px 10px 15px;
                    }
                }
            }

            .tours_proximite {
                h2 {
                    font-size: 30px;
                }

                .row {
                    display: none;
                }
            }
        }

        .gpx {
            display: none;
        }
    }

    section.liste {
        .dropdown {
            .findandinterets {
                .wrapper {
                    .interetstohave {
                        width: 100%;
                        justify-content: space-between;
                        .pdv {
                            flex-wrap: wrap;
                            button {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    section.blog {
        padding-top: 100px;

        .content {
            margin-bottom: 50px;
            padding-top: 50px;

            h1 {
                font-size: 34px;
            }

            div.text {
                p {
                    font-size: 20px;
                }
            }
        }

        .news {
            .news_wrapper {
                margin-bottom: 75px;
                .title {
                    flex-direction: column;
                    align-items: start;
                    h2 {
                        margin-bottom: 20px;
                    }
                }
            }

            .autres_guides {
                .text {
                    margin-bottom: 40px;

                    button {
                        display: none;
                    }
                }

                .images {
                    display: none;
                }

                .guides-swiper {
                    display: block;
                }

                .image {
                    min-height: 200px;

                    .littletext {
                        h2 {
                            font-size: 20px;
                        }

                        p {
                            font-size: 16px;
                        }
                    }
                }

                .section-footer {
                    display: block;
                }
            }
        }
    }

    section.apropos {
        padding-top: 100px;

        .content {
            height: 200px;
            margin-bottom: 50px;
            padding-top: 0;

            h1 {
                font-size: 50px;
            }
        }

        .title {
            p.breadcrumb {
                font-size: 16px;
                margin-bottom: 50px;
            }

            .quisommesnous {
                padding: 20px;

                .leftimage {
                    margin-bottom: 20px;

                    img {
                        width: 100%;
                    }
                }

                .righttext {
                    h2 {
                        font-size: 26px;
                        margin-bottom: 20px;
                    }

                    p {
                        font-size: 18px;
                    }
                }
            }

        }
    }

    section.tour_hero {
        .image {
            height: 250px;

            .title {
                height: 250px;
            }
        }
    }

    section.mosaique {
        padding-top: 100px;

        .content {
            height: 200px;
            margin-bottom: 50px;
            padding-top: 0;

            h1 {
                font-size: 50px;
            }
        }
    }

    section.footer {
        margin-top: 50px;
    }

    section.hero {
        .hero_content {
            height: 100vh;
        }

        .ken-burns-slideshow {
            height: 100vh;
            .slide {
                height: 100vh;
                .caracteristique {
                    height: 100vh;
                }
            }
        }
    }

    #guidosHighlight {
        height: 75vh !important;
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Max 576
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

@media screen and (max-width:576px) {

    section.hero {
        .hero-swiper {
            .swiper-slide {
                .hero_image {
                    .content {
                        .wrapper {
                            display: block;

                            .name {
                                margin-bottom: 15px;
                            }

                            .informations {
                                div {
                                    margin-left: 0;
                                    margin-right: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section.liste {
        .dropdown {
            .routes {
                .col-xl-4 {
                    .wrapper-tour {
                        .renseignements {
                            div {
                                padding: 0 15px;
                            }
                        }
                    }
                }
            }
        }
    }


    .select_display.select_display-desktop {
        flex-direction: column;
    }

}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Max 400
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

@media (max-width:400px) {
    section.hero {
        .hero_content {
            .wrapper_tour_types {
                width: 100%;
            }
        }
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                          Between 992 and 1200
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

@media (min-width:992px) and (max-width:1200px) {
    .dropdown {
        .findandinterets {
            .wrapper {
                align-items: start;
                flex-direction: column;
                .find {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
