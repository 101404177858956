@import 'variables';
@import 'font';
@import 'reset';
@import "~bootstrap/dist/css/bootstrap-grid.css";
@import "~bootstrap/scss/bootstrap-utilities.scss";
@import "responsive.scss";

body {
    overflow-x: hidden !important;
}

html {
    font-family: sans-serif;
    overflow-x: hidden !important;
    scroll-behavior: smooth;
}

body {
    font-family: 'Hellix';
    font-weight: normal;
    font-style: normal;
}

/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                                            For All
/*─
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/

.footermargin_null {
    margin-top: 0px !important;
}

.headerscroll {
    background: $fff !important;
}

.linksscroll {
    color: $bleu_principal !important;
}

.hamburgerscroll {
    stroke: $vert !important;
}

.logoscroll {
    color: $bleu_principal !important;
}

.is_scrolling {
    color: $bleu_principal  !important;
}

.seasonscroll {
    border: 2px solid $vert !important;
}

.seasontextscroll {
    color: $vert !important;
}

.navigation_active {
    height: 100vh !important;
    visibility: visible !important;
    opacity: 1 !important;
}

.btn {
    border-radius: 30px;
}

.select_display.select_display-mobile {
    display: none !important;

    .wrapper_select {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    button {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        margin-left: 0 !important;
    }
}

.swiper_button_disabled {
    display: none;
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Header
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

header {
    position: sticky;
    .header-wrapper {
        .header-content {
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .header-logo {
                display: flex;

                a {
                    display: flex;
                    align-items: center;
                    flex-direction: row;

                    img {
                        height: 35px;
                        margin-right: 10px;
                    }

                    span {
                        font-size: 26px;
                        color: $bleu_principal;
                        text-transform: uppercase;
                        font-weight: bold;
                        letter-spacing: 5px;
                        transition: 400ms;
                    }
                }
            }

            .header-links {
                flex-direction: row;
                font-size: 18px;
                font-weight: 600;

                .headeritem {
                    display: inline-block;
                    padding: 0 10px;

                    .headerlink {
                        color: $bleu_principal;
                        transition: 400ms;
                        &:hover {
                            opacity: 0.8;
                            transition: 400ms;
                        }
                    }
                }
            }

            .header-buttons {
                display: flex;
                justify-content: end;
                width: 140px;
                div {
                    display: flex;
                    align-items: center;

                    .season {
                        display: flex;
                        align-items: center;
                        .change-season {
                            width: 50px;
                            height: 25px;
                            border: 2px solid $vert;
                            border-radius: 15px;
                            position: relative;
                            margin-top: 2px;
    
                            input {
                                display: none;
                            }
    
                            label {
                                width: 100%;
                                height: 15px;
                                border-radius: 50%;
                                position: absolute;
                                top: 3px;
                                left: 2px;
                                z-index: -1;
                                cursor: pointer;
    
                                &::before {
                                    content: url('/assets/images/sunny.svg');
                                    position: absolute;
                                    top: -2px;
                                    left: 2px;
                                }
    
                                &::after {
                                    content: url('/assets/images/snowflake.svg');
                                    position: absolute;
                                    opacity: 0;
                                    top: -3px;
                                    left: 1px;
                                }
                            }
    
                            #switch:checked~label::before {
                                opacity: 0;
                            }
    
                            #switch:checked~label::after {
                                opacity: 1;
                                left: 29px;
                            }
                        }

                        .change-season-text {
                            color: $vert;
                            font-size: 16px;
                            font-weight: bold;
                            margin-left: 5px;
                        }
                    }

                    .change-language {
                        background-color: $gris_boutons;
                        border-radius: 15px;
                        padding: 10px 20px 11px 20px;
                        margin-left: 20px;

                        select {
                            color: $bleu_principal;
                            font-weight: 600;
                        }
                    }

                    .header-button {
                        border-radius: 15px;
                        background-color: $gris_boutons;
                        padding: 10px 20px 11px 20px;
                        margin-left: 10px;
                        font-weight: 600;
                    }
                }
            }

            .openmenu {
                display: none;

                .ham {
                    cursor: pointer;
                    width: 60px;
                    -webkit-tap-highlight-color: transparent;
                    transition: transform 400ms;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                .hamRotate.active {
                    transform: rotate(45deg);
                }

                .hamRotate180.active {
                    transform: rotate(180deg);
                }

                .line {
                    fill: none;
                    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
                    stroke: $vert;
                    stroke-width: 7.5;
                    stroke-linecap: round;
                }

                .ham4 .top {
                    stroke-dasharray: 40 121;
                }

                .ham4 .bottom {
                    stroke-dasharray: 40 121;
                }

                .ham4.active .top {
                    stroke-dashoffset: -68px;
                }

                .ham4.active .bottom {
                    stroke-dashoffset: -68px;
                }
            }

            .mobile_nav {
                height: 100vh;

                ul {
                    font-weight: 600;
                }
            }
        }

        .mobile-navigation {
            height: 0;
            opacity: 0;
            display: flex;
            visibility: hidden;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: height 400ms, opacity 400ms, visibility 400ms;

            ul {
                font-size: 22px;
                color: #212548;
                font-weight: 600;
                padding-bottom: 150px;

                li {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

header.sticky {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 20;
    transition: 400ms;

    .header-wrapper {
        .header-content {
            .header-logo {
                a {
                    span {
                        color: $fff;
                        transition: 400ms;
                    }
                }
            }

            .header-links {
                .headeritem {
                    .headerlink {
                        color: $fff;
                        transition: 400ms;
                    }

                    &:hover {
                        opacity: 0.8;
                        transition: 400ms;
                    }
                }
            }

            .header-buttons {
                div {
                    .season {
                        .change-season {
                            border: 2px solid $fff;
                            transition: 400ms;
                        }
                        .change-season-text {
                            color: $fff;
                            transition: 400ms;
                        }
                    }
                }
            }

            .openmenu {
                .line {
                    stroke: $fff;
                    transition: 400ms;
                }
            }
        }

    }
}

header:not(.sticky) {
    background-color: $fff;
    border-bottom: 1px solid #F4F4F6;

    .header-wrapper {
        .header-content {
            .header-links {
                .headeritem {
                    position: relative;
                    .headerlink {
                        &::after {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 1px;
                            position: absolute;
                            left: 0;
                            top: 61px;
                            margin: 0;
                            background: $bleu_principal;
                            opacity: 0;
                        }
                    }
                }

                .headerlink.active {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Footer
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

section.footer {
    margin-top: 100px;
    background-color: $gris_boutons;

    .wrapper {
        .row {
            padding-top: 50px;
            padding-bottom: 50px;
            border-bottom: 1px solid $gris3;


            .col-md-4 {
                text-align: center;
                div  {
                    display: inline-block;
                    text-align: left;
                    h3 {
                        color: $bleu_principal;
                        font-weight: 600;
                        font-size: 20px;
                        margin-bottom: 30px;
                    }
    
                    ul {
                        li {
                            &:not(:last-child) {
                                margin-bottom: 20px;
                            }
    
                            a {
                                color: $gris4;
                                transition: all .2s ease-in;
    
                                &:hover {
                                    color: $vert;
                                    transition: all .2s ease-in;
                                }
                            }
                        }
                    }
                }
            }
        }

        .footer-bottom {
            margin-top: 50px;
            padding-bottom: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-content {
                display: flex;

                .logo {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;

                    img {
                        height: 30px;
                        margin-right: 5px;
                    }

                    h3 {
                        font-size: 22px;
                        color: $bleu_principal;
                        font-weight: bold;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                    }
                }

                .content {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    color: $gris5;

                    ul {
                        display: flex;
                        align-items: center;

                        li {
                            display: flex;
                            margin-right: 10px;

                            span {
                                margin-right: 5px;
                            }
                        }

                        li.powered {
                            div {
                                display: flex; 
                                div {
                                    align-items: center;
                                    img {
                                        height: 15px;
                                        margin-left: 3px;
                                        margin-right: 3px;
                                    }   
                                }

                                a {
                                    margin-left: 3px;
                                }
                            }
                        }
                    }
                }
            }

            .right-content {
                display: flex;
                align-items: center;

                div {
                    background-color: $gris5;
                    padding: 10px 16px 11px 16px;
                    border-radius: 15px;
                    margin-right: 25px;

                    select {
                        font-weight: 600;
                        color: $fff;

                        option {
                            color: $bleu_principal;
                        }
                    }
                }

                a {
                    svg {
                        height: 20px;

                        path {
                            transition: all .2s ease-in;
                        }

                        &:hover path {
                            fill: $vert;
                            transition: all .2s ease-in;
                        }
                    }
                }

                a.facebook_link {
                    margin-right: 15px;
                }

                a.instagram_link {
                    margin-right: 15px;
                }
            }
        }
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                         Outils de diffusion
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

.outils_diffusion {
    .col-md-4 {
        margin-bottom: 50px;

        .text-wrapper {
            div {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                img {
                    width: 40px;
                    height: 40px;
                    margin-right: 20px;
                }

                h3 {
                    color: $jspencore;
                    font-size: 28px;
                    font-weight: 600;
                }
            }

            p {
                font-size: 18px;
                color: $bleu_principal;
                opacity: 60%;
                text-align: justify;
                margin-bottom: 15px;
            }

            a {
                font-size: 16px;
                width: fit-content;
                border-radius: 15px;
                background-color: $gris_boutons;
                padding: 8px 20px 10px 20px;
                color: $bleu_principal;
                border: 2px solid transparent;
                font-weight: 600;
                transition: all 0.2s ease-in;
                &:hover {
                    background-color: #FFFFFF;
                    border: 2px solid #212548;
                    transition: all 0.2s ease-in;
                }
            }
        }
    }
}

.outils_diffusion_mobile {
    .text-wrapper {
        div {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            img {
                width: 40px;
                margin-right: 20px;
            }

            h3 {
                color: $jspencore;
                font-size: 26px;
                font-weight: 600;
            }
        }

        p {
            font-size: 18px;
            color: $bleu_principal;
            opacity: 60%;
        }
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                 Section Hero
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

.section-hero {
    display: flex;
    justify-content: space-between;
    margin: 0 0 60px 0;
    font-weight: 600;

    h2 {
        font-size: 30px;
        color: $bleu_principal;
    }

    button {
        font-size: 18px;
        border-radius: 15px;
        background-color: $gris_boutons;
        padding: 8px 20px 10px 20px;
        color: $bleu_principal;
        border: 2px solid transparent;
        ;
        transition: all .2s ease-in;

        &:hover {
            background-color: $fff;
            border: 2px solid $bleu_principal;
            transition: all .2s ease-in;
        }
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                      Section Hero - Mobile
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

.mobile-regions {
    .section-hero {
        text-align: center;
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                      Section Incontournables - Mobile
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

.mobile-incontournables {
    .section-hero {
        text-align: center;
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                       Affichage des activités
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

.wrapper-tour {
    margin-bottom: 30px;

    .image {
        background-size: cover;
        border-radius: 15px;
        margin-bottom: 10px;
        aspect-ratio: 3/4;

        .type {

            aspect-ratio: 3/4;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 15px 15px;
            border-radius: 15px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.9252745043329832) 0%, rgba(0, 0, 0, 0.12975629705007008) 37%, rgba(0, 212, 255, 0) 100%);

            .wrapper-top {
                display: flex;
                // align-items: center;
                justify-content: end;

                .wrapper-image {
                    img {
                        width: 100px;
                    }
                }
            }
        }
    }

    .text {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .name {
            h3 {
                font-size: 22px;
                font-weight: 600;
                color: $bleu_principal;
            }

            p {
                font-size: 16px;
                color: $gris6;
                font-weight: 400;
            }
        }
    }

    .wrapper-renseignements {
        display: flex;
        flex-direction: column;

        .renseignements {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-right: 5px;
            }

            img {
                height: 15px;
                margin-right: 5px;
                width: auto;
            }

            p {
                color: $fff;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
    .wrapper-difficulty_activity {
        display: flex;
        flex-direction: row;
        align-content: start;
        justify-content: start;
        gap: .25rem;

        .difficulty {
            display: flex;
            align-items: flex-end;
            justify-content: end;
            margin-bottom: 5px;
            p {
                font-size: 12px;
                font-weight: 600;
                color: $fff;
                border-radius: 15px;
                padding: 4px 15px 5px 15px;
            }
        }

        .rando {
            display: flex;
            align-items: flex-end;
            justify-content: end;
            margin-bottom: 5px;
            p {
                font-size: 12px;
                font-weight: 600;
                color: $bleu_principal;
                background-color: $fff;
                border-radius: 15px;
                padding: 4px 15px 5px 15px;
            }
        }
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                    Dropdown
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

.liste.itineraires {
    margin-top: 35px;
}

.dropdown {
    .itineraires_dropdown {
        margin-bottom: 30px;

        .wrapper {
            display: flex;
            align-items: center;
            width: 100%;

            .typeandplace {
                display: flex;
                width: 50%;

                .wrapper-typeandplace {
                    width: 50%;
                    margin-right: 10px;

                    .select_display {
                        display: flex;

                        .wrapper_select {
                            display: flex;
                            align-items: center;
                            border-radius: 15px;
                            border: 1px solid $gris4;
                            // padding: 17px 10px 15px 10px;
                            width: 100%;

                            .wrapper-picto {
                                margin-right: 5px;
                                padding: 15px 0 15px 10px;

                                img {
                                    height: 20px;
                                }
                            }

                            select {
                                width: 100%;
                                color: $gris4;
                                opacity: 100%;
                                font-weight: 600;
                                cursor: pointer;
                                padding: 15px 0 15px 0;
                                margin-right: 10px;
                            }
                        }

                        button {
                            width: 35%;
                            background-color: $vert;
                            border-radius: 15px;
                            padding: 18px 16px;
                            font-size: 16px;
                            color: $fff;
                            margin-left: 10px;
                            font-weight: 600;
                        }
                    }
                }
            }

            .specificites {
                display: flex;
                width: 50%;

                .wrapper-specificites {
                    width: 50%;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }

                    .select_display {
                        display: flex;

                        .wrapper_select {
                            display: flex;
                            align-items: center;
                            background-color: #F4F4F6;
                            border-radius: 15px;
                            width: 100%;

                            .wrapper-picto {
                                margin-right: 5px;
                                padding: 15px 0 15px 10px;

                                img {
                                    height: 20px;
                                }
                            }

                            select {
                                width: 100%;
                                color: $noir2;
                                opacity: 100%;
                                font-weight: 600;
                                cursor: pointer;
                                padding: 15px 0 15px 0;
                                margin-right: 10px;
                            }
                        }

                        button {
                            width: 35%;
                            background-color: $vert;
                            border-radius: 15px;
                            padding: 18px 16px;
                            font-size: 16px;
                            color: $fff;
                            margin-left: 10px;
                            font-weight: 600;
                        }
                    }
                }
            }

            .specificites-mobile {

                .twobytwo {
                    display: flex;

                    &:first-child {
                        margin-bottom: 10px;
                    }

                    .wrapper-specificites {
                        width: 50%;

                        &:first-child {
                            margin-right: 10px;
                        }

                        .select_display {
                            .wrapper_select {
                                display: flex;
                                align-items: center;
                                background-color: #F4F4F6;
                                border-radius: 15px;
                                margin-right: 10px;
                                padding: 10px;
                                width: 100%;

                                .wrapper-picto {
                                    margin-right: 5px;

                                    img {
                                        height: 20px;
                                    }
                                }

                                select {
                                    width: 100%;
                                    color: $noir2;
                                    opacity: 100%;
                                    font-weight: 600;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .region_dropdown {
        .wrapper {
            .typeandplace {
                width: 33.3%;
                .wrapper-typeandplace {
                    width: 100%;
                }
            }
            .specificites {
                width: 66.6%;
            }
        }
    }

    .findandinterets {
        padding-bottom: 20px;
        border-bottom: 1px solid $gris3;

        .wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .find {
                p {
                    color: $gris6;
                    font-weight: 600;
                    font-size: 16px;
                }
            }

            .interetstohave {
                display: flex;
                align-items: center;
                flex-direction: row;

                .pdv {
                    display: flex;
                    align-items: center;

                    .poi {
                        font-size: 16px;
                        font-weight: 600;
                        color: $gris6;
                        padding: 8px 20px 10px 20px;
                        border: 2px solid $gris6;
                        border-radius: 30px;
                        margin-right: 5px;
                    }

                    .poi.active {
                        border: 2px solid $bleu_principal;
                        background-color: $gris_boutons;
                    }
                }

                .interets {
                    display: flex;
                    align-items: center;
                    border-radius: 30px;
                    background-color: #F4F4F6;
                    padding: 11px 15px 10px 15px;

                    img {
                        height: 20px;
                        margin-right: 5px;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 600;
                        color: $bleu_principal;
                    }
                }
            }
        }

        .find-mobile {
            display: none;

            p {
                color: $gris6;
                font-weight: 600;
                font-size: 16px;
            }
        }
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Section Footer
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

.section-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    text-align: center;

    button {
        font-size: 20px;
        border-radius: 15px;
        background-color: $gris_boutons;
        padding: 10px 20px;
        font-weight: 600;
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Modal
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

.modal_container {
    position: fixed;
    top: 0;
    opacity: 0;
    visibility: hidden;
    width: 100vw;
    height: 0;
    transition: height 400ms, opacity 400ms, visibility 400ms;
    z-index: 99999;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .modal-trigger {
        .modal {
            width: 100%;
            height: 100vh;
            background: #898C9E;
            position: absolute;

            .close {
                padding: 10px 10px;
                border-radius: 50%;
                font-size: 18px;
                position: absolute;
                top: 10px;
                right: 10px;
                background: $gris_boutons;
                color: $fff;
                z-index: 999;
            }

            #guidosHighlight {
                height: 100vh !important;
            }
        }
    }
}

.modal_container.active {
    height: 100vh !important;
    opacity: 1 !important;
    visibility: visible !important;
}


/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Link to map
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

.linktofullmap {
    display: flex;
    align-items: center;
    background-color: $fff;
    padding: 10px 15px 12px 15px;
    border-radius: 15px;
    cursor: pointer;

    p {
        font-size: 16px;
        color: $bleu_principal;
        font-weight: 600;
        margin-right: 10px;
    }

    img {
        height: 15px;
        width: auto;
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Widget
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

#guidosHighlight {

    .mapboxgl-map {
        border-radius: 30px;
    }

    .Card_info__JDc2p.Card_left-btn__2IOtc {
        display: none;
    }

    .RightButtons_language-selector__1SOjo.css-hrkjyw-container {
        display: none;
    }

    .RightButtons_button-right-style__EFwuY.RightButtons_w-svg__v7B9o {
        display: none;
    }

    .Elevation_elevation__MzBvL {
        width: 100% !important;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }
}

/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                                            Homepage
/*─
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Hero
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

section.hero {
    .ken-burns-slideshow {
        $total-slides: 3;
        $interval: 5s;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 75vh;
        overflow: hidden;
        list-style-type: none;

        .slide {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 75vh;
            background-size: cover;
            background-position: center;
            opacity: 0;
            animation: kenburns $total-slides * $interval linear infinite;

            @for $i from 1 through $total-slides {
                &:nth-child(#{$i}) {
                    animation-delay: $interval * ($i - 1);
                }
            }

            .caracteristique {
                height: 75vh;
                display: flex;
                flex-direction: row;
                align-items: end;
                justify-content: end;
                padding-bottom: 20px;

                .content {
                    align-items: center;
                    justify-content: end;

                    .name {
                        display: flex;
                        justify-content: end;
                        margin-bottom: 5px;

                        h2 {
                            margin-left: 5px;
                            font-size: 16px;
                            color: $fff;
                            font-weight: bold;
                        }
                    }

                    .informations {
                        display: flex;
                        justify-content: right;

                        div {
                            display: flex;
                            align-items: center;
                            &:not(:first-child) {
                                margin-left: 10px;
                            }

                            img {
                                height: 20px;
                            }

                            p {
                                font-size: 16px;
                                font-weight: normal;
                                color: $fff;
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    #slide1 {
        background-image: linear-gradient(rgba(16, 16, 16, 0.2),
                rgba(16, 16, 16, 0.2)),
            url("../images/hero.jpg");
    }

    #slide2 {
        background-image: linear-gradient(rgba(16, 16, 16, 0.4),
                rgba(16, 16, 16, 0.4)),
            url("../images/lacdesvaux.jpg");
    }

    #slide3 {
        background-image: linear-gradient(rgba(16, 16, 16, 0.4),
                rgba(16, 16, 16, 0.4)),
            url("../images/exemple1.jpg");
    }

    #slide4 {
        background-image: linear-gradient(rgba(16, 16, 16, 0.4),
                rgba(16, 16, 16, 0.4)),
            url("../images/exemple2@2x.jpg");
    }

    @keyframes kenburns {
        10% {
            opacity: 1;
        }

        25% {
            opacity: 1;
            transform: scale(1);
        }

        40% {
            opacity: 0;
            transform: scale(1);
        }
    }

    .hero_content {
        height: 75vh;
        color: white;
        display: flex;
        align-items: center;

        .container {
            z-index: 11;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
        }

        .text {
            margin-bottom: 40px;
            text-align: center;

            h1 {
                font-size: 52px;
                color: $fff;
                font-weight: 600;
                line-height: 1;
                margin-bottom: 10px;
            }

            p {
                font-size: 24px;
                color: $gris3;
            }
        }

        .wrapper_tour_types {
            width: 55%;
            background-color: $fff;
            border-radius: 30px;
            box-shadow: 0px 0px 60px rgba($color: #000000, $alpha: 0.25);
            padding: 20px;

            .tour_types {
                margin-bottom: 15px;

                .slide-container {
                    width: 100%;

                    .slide-content {
                        overflow: hidden;

                        .card {
                            .card-content {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                cursor: pointer;

                                &:hover .wrapper-image {
                                    background-color: $fff;
                                    border: 2px solid $bleu_principal;
                                    transition: all .2s ease-in;
                                }

                                &:hover .wrapper-image img {
                                    transition: all .2s ease-in;
                                }

                                &:hover p {
                                    color: $bleu_principal;
                                    transition: all .2s ease-in;
                                }

                                .wrapper-image {
                                    background-color: $gris_boutons;
                                    border: 2px solid transparent;
                                    padding: 5px 20px;
                                    border-radius: 15px;
                                    margin-bottom: 5px;
                                    transition: all .2s ease-in;


                                    img {
                                        width: 50px;
                                        height: 81px;
                                        transition: all .2s ease-in;
                                    }
                                }

                                p {
                                    color: #7B7E93;
                                    font-weight: 600;
                                    text-transform: uppercase;
                                    transition: all .2s ease-in;
                                }
                            }

                            .activity_active {
                                .wrapper-image {
                                    background-color: $fff;
                                    border: 2px solid $bleu_principal;
                                }

                                p {
                                    color: $bleu_principal;
                                }
                            }
                        }
                    }
                    .prev_next {
                        .swiper-button-prev {
                            position: absolute;
                            left: 0;
                            color: $fff;
                            background-color: $vert;
                            border-radius: 50%;
                            height: 10px;
                            width: 10px;
                            padding: 15px;
                            &::after {
                                font-size: 14px;
                                font-weight: bold;
                            }
                        }

                        .swiper-button-next {
                            position: absolute;
                            right: 0;
                            color: $fff;
                            background-color: $vert;
                            border-radius: 50%;
                            height: 10px;
                            width: 10px;
                            padding: 15px;
                            &::after {
                                font-size: 14px;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            .select_display {
                display: flex;
                gap: .5rem;
                .wrapper_select {
                    display: flex;
                    align-items: center;
                    background-color: #F4F4F6;
                    border-radius: 15px;
                    padding: 15px;
                    flex-grow: 1;
                    .wrapper-picto {
                        margin-right: 5px;

                        img {
                            height: 20px;
                        }
                    }

                    select {
                        width: 100%;
                        color: $noir2;
                        opacity: 100%;
                        font-size: 18px;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }

                button {
                    background: radial-gradient(circle, $vert 0%, $vert 100%);
                    border-radius: 15px;
                    padding: 11px 20px 14px 20px;
                    font-size: 18px;
                    color: $fff;
                    font-weight: 600;
                    transition: all .2s ease;

                    &:hover {
                        color: $fff;
                        background: radial-gradient(circle, $vert 0%, #52877b 100%);
                        transition: all .2s ease;
                    }
                }
            }
        }

        .hero-wrapper-content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Régions
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

section.regions,
section.mobile-regions {
    height: auto;
    margin-top: 100px;

    .regions-swiper,
    .mobile-regions-swiper {
        .swiper-wrapper {
            .swiper-slide {
                .swiper-card {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 300px;
                    border-radius: 30px;
                    background-size: cover;
                    z-index: 2;

                    .informations {
                        text-align: center;
                        color: $fff;
                        z-index: 3;

                        h2 {
                            font-size: 38px;
                            color: $fff;
                            font-weight: 600;
                        }

                        p {
                            font-size: 20px;
                        }
                    }

                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        border-radius: 30px;
                        left: 0;
                        bottom: 0;
                        background: black;
                        width: 100%;
                        height: 100%;
                        opacity: 0.2;
                        z-index: 1;
                    }
                }
            }
        }

        .prev_next {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;

            .swiper-button-prev,
            .swiper-button-next {
                position: initial;
                color: $bleu_principal;
                width: 10px;
                height: 10px;
                padding: 20px;
                background-color: $gris_boutons;
                border-radius: 50%;
                margin: 0 10px 0 10px;

                &::after {
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
    }

    .regions-swiper {
        .swiper-wrapper {
            .swiper-slide {
                opacity: .2;
            }

            .swiper-slide-visible {
                opacity: 1;
            }
        }
    }
}

section.mobile-regions {
    display: none;
}

.idees-swiper {
    .swiper-wrapper {
        .swiper-slide {
            opacity: .2;
        }

        .swiper-slide-visible {
            opacity: 1;
        }
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Incontournables
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

section.incontournables {
    height: auto;
    margin-top: 100px;

    .images {
        .leftdiv {
            padding-right: 15px !important;

            .background {
                min-height: 600px;
                background-size: cover;
                border-radius: 30px;
                position: relative;

                div {
                    position: absolute;
                    color: $fff;
                    bottom: 30px;
                    left: 30px;
                    right: 30px;

                    h3 {
                        font-size: 30px;
                        font-weight: 600;
                        margin-bottom: 15px;
                        line-height: 1.1;
                    }

                    p {
                        font-size: 18px;
                    }
                }
            }
        }

        .rightdiv {
            display: flex;
            flex-direction: column;
            padding-left: 15px !important;

            .background {
                flex-grow: 1;
                border-radius: 30px;
                position: relative;
                background-size: cover;

                div {
                    position: absolute;
                    color: $fff;
                    bottom: 30px;
                    left: 30px;
                    right: 30px;
                }
            }

            .background:first-child {
                margin-bottom: 40px;
                background-position: center;

                div {
                    h3 {
                        font-size: 30px;
                        font-weight: 600;
                        margin-bottom: 15px;
                        line-height: 1.1;
                    }

                    p {
                        font-size: 18px;
                    }
                }
            }

            .background:last-child {
                div {
                    h3 {
                        font-size: 30px;
                        font-weight: 600;
                        margin-bottom: 15px;
                        line-height: 1.1;
                    }

                    p {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

section.mobile-incontournables {
    height: auto;
    margin-top: 100px;
    display: none;

    .images {
        .background {
            background-image: url('../images/exemple2@2x.jpg');
            background-size: cover;
            border-radius: 30px;
            margin-bottom: 15px;

            div {
                min-height: 250px;
                display: flex;
                flex-direction: column;
                justify-content: end;
                padding: 20px;

                h2 {
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 15px;
                    color: $fff;
                }

                p {
                    color: $fff;
                }
            }
        }
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Incontournables
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Idées
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

section.idees {
    margin-top: 100px;
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Outils
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

section.outils {
    margin-top: 50px;
}

section.outils_mobile {
    display: none;
    margin-top: 70px;

    .section-footer {
        justify-content: start;
    }
}

/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Image
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

section.image {
    margin-top: 50px;

    .background {
        background-image: url('../images/footer.jpg');
        background-size: cover;
        background-position: center;
        border-radius: 30px;

        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 300px;
            padding: 40px 0 40px 40px;
            font-weight: 600;

            h2 {
                font-size: 30px;
                color: $fff;
            }

            .button {
                background-color: $fff;
                color: $bleu_principal;
                font-size: 18px;
                border-radius: 15px;
                padding: 7px 20px 10px 20px;
                width: max-content;
            }
        }
    }
}

/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                                        Page : Itineaires
/*─
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/

section.liste {
    .col-lg-8 {
        .routes {
            margin-top: 40px;
        }
    }

    .routes_map {
        .nomachanger {
            position: sticky;
            top: 25px;
            height: calc(100vh - 100px);
            background-color: beige;
            border-radius: 30px;
            margin-bottom: 50px;

            .openmap {
                .wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    padding: 15px 15px 0 0;
                }
            }
        }
    }
}

.mobile-openmap {
    display: none;
}

/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                                        Page : Verbier
/*─
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/

section.tour_hero {
    margin-bottom: 50px;
    background-color: black;
    .image {
        height: 300px;
        background-size: cover;
        background-position: center;
        opacity: 0.4;
    }
    .title {
        position:absolute;
        z-index: 10;
        height: 300px;
        display: flex;
        align-items: center;

        div {
            line-height: 1;
            h1 {
                color: $fff;
                font-size: 3rem;
                font-weight: bold;
            }
    
            h3 {
                font-size: 1.5rem;
                color: $fff;
                opacity: 55%;
            }
    
            @include media-breakpoint-up(lg){
                h1 {
                    font-size: 89px;
                }
                h3 {
                    font-size: 52px;
                }
            }
        }
    }
}

.routes_map {
    .verbier {
        height: 800px;
        background-color: beige;
        border-radius: 30px;
        margin-bottom: 50px;

        .openmap {
            .wrapper {
                display: flex;
                align-items: center;
                justify-content: end;
                padding: 15px 15px 0 0;
            }
        }
    }

    .meteo {
        background-color: $gris_boutons;
        background-image: url('../images/line_topo_background.svg');
        background-size: cover;
        border-radius: 30px;

        .content {
            padding: 25px;

            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 40px;

                p {
                    font-size: 18px;
                    color: $bleu_principal;
                    font-weight: 600;
                }

                button {
                    background-color: $bleu_principal;
                    padding: 10px 15px;
                    color: $fff;
                    border-radius: 15px;
                    font-weight: 600;
                    transition: all .2s ease-in;

                    &:hover {
                        color: $fff;
                        background-color: $vert;
                        transition: all .2s ease-in;
                    }
                }
            }

            .previsions {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 25px;

                .temps {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;

                    div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 0 10px 0 15px;

                        p {
                            font-size: 14px;
                            color: $bleu_principal;
                            opacity: 40%;
                            margin-bottom: 10px;
                        }

                        img {
                            height: 35px;
                        }
                    }
                }

                .soleil {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 15px;

                    div {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;

                        img {
                            margin-right: 10px;
                            height: 30px;
                        }

                        p {
                            font-size: 14px;
                            color: $bleu_principal;
                            opacity: 40%;
                        }
                    }
                }
            }

            .webcams {
                .title {
                    font-size: 16px;
                    color: $bleu_principal;
                    font-weight: 600;
                    margin-bottom: 25px;
                }

                .webcams_wrapper {
                    .line {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;

                        img {
                            height: 20px;
                            width: auto;
                            margin-right: 5px;
                        }

                        .distance {
                            font-size: 14px;
                            font-weight: bold;
                            margin-right: 5px;
                        }

                        .lieu {
                            font-size: 14px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }
    }
}

/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                                        Page : Tour
/*─
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/

section.informations {
    margin-bottom: 50px;
    margin-top: 35px;

    .general_text {
        .hero {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 40px;

            .lieu {
                h1 {
                    font-size: 30px;
                    font-weight: 600;
                    color: $bleu_principal;
                }

                p {
                    font-size: 24px;
                    color: $gris6 ;
                }
            }

            .informations {
                display: flex;
                flex-direction: row;

                .infos {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 0 15px 0 15px;

                    &:not(:last-child) {
                        border-right: 1px solid $gris6;
                    }

                    img {
                        height: 30px;
                        width: auto;
                        margin-bottom: 5px;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 600;
                        color: $bleu_principal;
                    }
                }

                .points {
                    margin-right: 20px;

                    .line {
                        display: flex;
                        align-items: center;
                        padding-left: 15px;
                        margin-bottom: 5px;

                        img {
                            width: 13px;
                            margin-right: 5px;
                        }

                        .levels {
                            height: 13px;
                            width: 13px;
                            border-radius: 50%;
                            background-color: $vert;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        .bigimage {
            background-size: cover;
            min-height: 500px;
            border-radius: 30px;
            margin-bottom: 40px;
        }

        .informations_balalde {
            margin-bottom: 40px;

            .wrapper {
                display: flex;
                justify-content: space-between;

                .caracteristiques {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 16px;
                    margin-bottom: 10px;

                    .wrapper-pois {
                        padding: 0;
                        margin: 0;
                        -ms-box-orient: horizontal;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -moz-flex;
                        display: -webkit-flex;
                        display: flex;

                        p {
                            padding: 8px 20px 10px 20px;
                            border-radius: 30px;
                            width: max-content;
                            font-weight: 600;
                            margin: 0 10px 10px 0;
                        }

                        .difficulty {
                            color: $fff;
                            background-color: $noir;
                        }

                        .type {
                            color: $bleu2;
                            border: 1px solid $bleu2;
                        }

                        .pois {
                            color: $gris6;
                            border: 1px solid $gris6;
                        }
                    }

                    .wrap {
                        -webkit-flex-wrap: wrap;
                        flex-wrap: wrap;
                    }
                }

                .logo {
                    img {
                        border-radius: 100px;
                        height: 42px;
                        width: auto;
                    }
                }
            }
        }

        .firsttext {
            margin-bottom: 50px;

            p {
                font-size: 20px;
                color: $noir2;
                text-align: justify;
            }
        }

        .mobile-images {
            display: none;

            .tour-swiper {
                .image {
                    min-height: 250px;
                    background-size: cover;
                    border-radius: 30px;
                }
            }
        }

        .first_images {
            margin-bottom: 50px;

            .col-md-6 {
                img {
                    border-radius: 30px;
                }
            }
        }

        .mobile-gpx {
            margin-bottom: 50px;
            display: none;

            .mobile-gpx-map {
                border-radius: 30px;
                width: 100%;
                margin-bottom: 15px;
            }

            .mobile-gpx_buttons {

                .wrapper {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    justify-content: center;
                }

                a, button {
                    display: flex;
                    align-items: center;
                    background-color: $fff;
                    border-radius: 15px;
                    padding: 10px 15px;
                    margin-right: 10px;
                    border: 1px solid $gris6;

                    img {
                        height: 15px;
                        width: 15px;
                        margin-right: 10px;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 600;
                        color: $bleu_principal;
                    }
                }

                .partager {
                    margin-left: auto;
                    margin-right: auto;
                    width: fit-content;
                }

                .mobile-fullopen {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .secondtext {
            margin-bottom: 50px;

            p {
                margin-bottom: 30px;
                font-size: 20px;
                color: $noir2;
                text-align: justify;
            }
        }

        .second_images {
            margin-bottom: 50px;

            .col-md-6 {
                padding-left: 0;
                padding-right: 0;

                img {
                    border-radius: 30px;
                }

                &:first-child {
                    padding-right: 15px;
                }

                &:last-child {
                    padding-left: 15px;
                }
            }
        }

        .video {
            margin-bottom: 50px;
            position: relative;

            video {
                border-radius: 30px;
            }

            .overlay-video {
                width: max-content;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -110%);
                display: flex;
                flex-direction: column;
                align-items: center;

                p {
                    color: $fff;
                    font-size: 18px;
                    font-weight: 600;
                    background-color: $bleu3;
                    border-radius: 30px;
                    padding: 10px 20px 12px 20px;
                    margin-bottom: 5px;
                }

                img {
                    width: 50px;
                }
            }
        }

        .tours_proximite {
            h2 {
                font-size: 45px;
                color: $jspencore;
                font-weight: 600;
                margin-bottom: 50px;
            }

            .mobile-idees-swiper {
                display: none;
            }
        }

        .mobile-tour-hero {
            display: none;

            .image {
                width: 100vw;
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;
                min-height: 250px;
                background-size: cover;
                margin-bottom: 25px;
            }

            .tour-hero {
                .wrapper-lieu_points {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 30px;

                    .lieu {
                        h2 {
                            font-size: 30px;
                            font-weight: 600;
                            color: $bleu_principal;
                        }

                        p {
                            font-size: 20px;
                            color: $gris6;
                        }
                    }

                    .points {
                        .line {
                            display: flex;
                            align-items: center;
                            margin-bottom: 5px;

                            img {
                                width: 15px;
                                margin-right: 5px;
                            }

                            .levels {
                                height: 15px;
                                width: 15px;
                                border-radius: 50%;
                                background-color: $vert;
                                margin-right: 5px;
                            }
                        }
                    }
                }

                .informations {
                    display: flex;
                    align-items: center;
                    margin-bottom: 40px;
                    justify-content: center;

                    .infos {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 0 10px 0 10px;

                        &:not(:last-child) {
                            border-right: 1px solid $gris6;
                        }

                        img {
                            height: 25px;
                            width: auto;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 16px;
                            font-weight: 600;
                            color: $bleu_principal;
                        }
                    }
                }
            }
        }
    }

    .gpx {
        .gpx_wrapper {
            position: sticky;
            top: 25px;

            .gpxmap {
                margin-bottom: 20px;

                .openmap {
                    .wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        padding: 15px 15px 0 0;
                    }
                }
            }
        }

        .gpx_buttons {
            .wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
            }

            a, button {
                display: flex;
                align-items: center;
                background-color: $fff;
                border-radius: 15px;
                padding: 10px 15px 12px 15px;
                margin-right: 10px;
                border: 1px solid $gris6;

                img {
                    height: 15px;
                    width: 15px;
                    margin-right: 10px;
                }

                p {
                    font-size: 18px;
                    font-weight: 600;
                    color: $bleu_principal
                }
            }

            #openfull {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                                        Page : Article
/*─
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/

section.blog {
    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F4F4F6;
        margin-bottom: 100px;
        background-size: cover;
        background-position: center;
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;

        a.breadcrumb {
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
            color: $gris7;
            margin-bottom: 20px;
            letter-spacing: 1.8px;

            span {
                color: $noir;
            }
        }

        h1 {
            color: $bleu_principal;
            font-size: 48px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        div.text {
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;

            p {
                color: $bleu_principal;
                font-size: 24px;
            }
        }
    }

    .news {
        .news_wrapper {
            margin-bottom: 100px;
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;

            .title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 40px;
                h2 {
                    font-size: 36px;
                    font-weight: 600;
                    color: $bleu_principal;
                }

                a {
                    font-weight: 600;
                    width: fit-content;
                    font-size: 18px;
                    border-radius: 15px;
                    background-color: #F4F4F6;
                    padding: 8px 20px 10px 20px;
                    color: #212548;
                    border: 2px solid transparent;
                    transition: all 0.2s ease-in;
                    &:hover {
                        background-color: #FFFFFF;
                        border: 2px solid #212548;
                        transition: all 0.2s ease-in;   
                    }
                }
            }

            .text {
                p{
                    font-size: 18px;
                    color: $bleu_principal;
                    text-align: justify;
                    margin-bottom: 50px;   
                }
            }

            .image {
                height: 400px;
                border-radius: 30px;
                background-size: cover;
                background-position: center;
            }
        }

        .autres_guides {
            .text {
                display: flex;
                justify-content: space-between;
                margin: 0 0 60px 0;
                font-weight: 600;
                margin-bottom: 85px;

                h2 {
                    font-size: 30px;
                    color: #212548;
                }

                button {
                    font-size: 18px;
                    border-radius: 15px;
                    background-color: #F4F4F6;
                    padding: 8px 20px 10px 20px;
                    color: $bleu_principal;
                    border: 1px solid transparent;
                    transition: all 0.2s ease-in;

                    &:hover {
                        background-color: $fff;
                        border: 1px solid $bleu_principal;
                        transition: all 0.2s ease-in;
                    }
                }
            }

            .guides-swiper {
                display: none;
            }

            .image {
                min-height: 350px;
                border-radius: 30px;
                position: relative;
                background-size: cover;

                .littletext {
                    position: absolute;
                    left: 30px;
                    bottom: 30px;

                    h2 {
                        color: $fff;
                        font-size: 36px;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 22px;
                        color: $fff;
                    }
                }
            }

            .section-footer {
                display: none;
                justify-content: start;
            }
        }
    }
}

/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                                        Page : A propos
/*─
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/

section.apropos {
    .content {
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../images/line_background_blue.svg');
        background-color: $fff;
        margin-bottom: 100px;
        background-size: cover;
        background-position: bottom;

        h1 {
            font-size: 72px;
            text-transform: uppercase;
            color: $bleu_principal;
            font-weight: bold;
        }
    }

    .title {
        text-align: center;

        p.breadcrumb {
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
            color: $gris7;
            margin-bottom: 65px;
            letter-spacing: 1.8px;

            span {
                color: $noir;
            }
        }

        .quisommesnous {
            background-color: $gris_boutons;
            padding: 100px;
            border-radius: 30px;
            margin-bottom: 100px;

            .leftimage {
                display: flex;
                align-items: center;

                img {
                    max-width: 450px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .righttext {
                text-align: left;

                h2 {
                    font-size: 36px;
                    font-weight: 600;
                    color: $bleu_principal;
                    margin-bottom: 50px;
                }

                p {
                    font-size: 22px;
                    line-height: 1.4;
                    text-align: justify;
                    color: $bleu_principal;
                }
            }
        }
    }

    .diffusion {
        .text {
            margin: 0 0 60px 0;
            font-weight: 600;

            h2 {
                font-size: 30px;
                color: $bleu_principal;
            }
        }
    }
}

/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                                        Page : Blog
/*─
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/

section.mosaique {
    .content {
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../images/line_background_blue.svg');
        background-color: $fff;
        margin-bottom: 100px;
        background-size: cover;
        background-position: bottom;

        h1 {
            font-size: 72px;
            text-transform: uppercase;
            color: $bleu_principal;
            font-weight: bold;
        }
    }
    .container {
        .articles-text {
            h2 {
                font-size: 30px;
                color: $bleu_principal;
                font-weight: 600;
            }

            button {
                font-size: 18px;
                border-radius: 15px;
                background-color: #F4F4F6;
                padding: 8px 20px 10px 20px;
                font-weight: 600;
                color: #212548;
                border: 2px solid transparent;
                transition: all 0.2s ease-in;
                &:hover {
                    background-color: #FFFFFF;
                    border: 2px solid #212548;
                    transition: all 0.2s ease-in;
                }
            }
        }
    }
}

#pagination {
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    .choose_page{
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        background-color: $gris_boutons;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .2s;
        &:hover{
            background-color: $gris3;
        }
        &.active{
            background-color: $vert;
            color: white;
        }
        span{
            margin-top: -4px;
        }
    }
}

.results{
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.grid{
    display: grid;
    gap:1rem 2rem;
    &.grid-cols-3{
        grid-template-columns: repeat(3,minmax(0,1fr));
        @include media-breakpoint-down(xl) {
            grid-template-columns: repeat(3,minmax(0,1fr));

        }
        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(2,minmax(0,1fr));

        }
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1,minmax(0,1fr));

        }
    }
    &.grid-cols-4{
        grid-template-columns: repeat(4,minmax(0,1fr));
        @include media-breakpoint-down(xl) {
            grid-template-columns: repeat(3,minmax(0,1fr));

        }
        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(2,minmax(0,1fr));

        }
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1,minmax(0,1fr));

        }
    }
}


select{
    -webkit-appearance:none;
}

.wrapper-tour{
    .wrapper-image img{
        background-color: rgba(255,255,255,1);
        border-radius: 100px;
    }
}


.routes_map{

    padding-left: 2rem;
    @include media-breakpoint-down(lg) {
        padding-left: 0rem;

    }
    padding-right: 0;
}

#tour_types .card-wrapper p{
    font-size: 75%;
}

#cgs {
    h1{
        font-size: 30px;
        color: #212548;
        font-weight: 600;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    p{
        margin-top: 1rem;
        font-weight: normal;
    }
    a{
        display: inline;
        text-decoration: underline;
        color: #002C52;
    }
    ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }

    ol > li {
        font-weight: bold;
        display: table;
        counter-increment: item;
        margin-top: 1rem;
    }

    ol > li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
    }

    li ol > li {
        font-weight: normal;
        margin-top: 1rem;
    }

    li ol > li:before {
        content: counters(item, ".") " ";
    }
}

/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                                        Page : Erreur
/*─
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
/*────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/

.erreur {
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    .content {
        color: $fff;
        text-align: center;
        h1 {
            font-size: 74px;
            font-weight: bold;
        }

        p {
            font-size: 34px;
            line-height: 1.1;
            font-weight: 600;
        }

        a {
            width: fit-content;
            font-weight: 600;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;
            font-size: 16px;
            border-radius: 15px;
            background-color: $gris_boutons;
            padding: 8px 20px 10px 20px;
            color: $bleu_principal;
            border: 2px solid transparent;
            transition: all .2s ease-in;
    
            &:hover {
                background-color: transparent;
                border: 2px solid $fff;
                transition: all .2s ease-in;
                color: $fff;
            }
        }
    }
}
