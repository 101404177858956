@font-face {
    font-family: 'Hellix';
    src: url('../fonts/Hellix-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Hellix';
    src: url('../fonts/Hellix-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Hellix';
    src: url('../fonts/Hellix-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Hellix';
    src: url('../fonts/Hellix-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}