/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      Couleurs
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
$fff: #FFFFFF;
$bleu_principal: #212548;
$gris_boutons: #F4F4F6;
$gris2 :#7B7E93;
$gris3 : #B4B6C2;
$gris4 : #7F8296;
$gris5 : #85889B;
$gris6 : #898C9E;
$bleu2 : #00000029;
$jspencore : #152740;
$noir: #000000;
$noir2 : #112238;
$gris7 : #6A6A6A;
$bleunoir : #18283E;
$vert : #00AB84;
$bleu : #3BBFF1;
$bleu2 : #002C52;
$bleu3: #30B2D1;
$orange : #F56E23;