/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                      RESET
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
*,
*::before,
*::after {
  box-sizing: border-box !important;
}

html {
  -webkit-text-size-adjust: none;
  /*─ Debug font size on iphone when landscape orientation ─*/
  -webkit-font-smoothing: subpixel-antialiased;
  /*─ Debug safari font weight when fixed element on the page ─*/
  -webkit-font-smoothing: antialiased;
  /*─ idem : Debug safari font weight when fixed element on the page ─*/
}

html,
body,
nav,
header,
footer,
div,
span,
h1,
h2,
h3,
p,
a,
img,
ul,
li,
table,
form,
label,
input,
textarea,
select,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  //font-family: inherit;
  font-size: 100%;
}

ul {
  list-style: none;
}

li {
  list-style: none;
}

input,
textarea,
select,
button {
  display: block;
  box-sizing: border-box;
  border-radius: 0;
  outline: none;
  border: none;
  background: none;
  color: inherit;
}

input:focus,
textarea:focus,
button:focus {
  outline: none;
}

input[type='submit']:hover,
button:hover {
  cursor: pointer;
}

input::-ms-clear {
  display: none;
  /*─ IE11 → remove cross to close ─*/
}

textarea {
  overflow: auto;
  /*─ Hide scroll bar on IE ─*/
}

a {
  display: block;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

img,
video,
svg,
picture {
  display: block;
}

img,
video {
  width: 100%;
  height: auto;
}

// svg {
//   width: 100%;
//   height: 100%;
// }

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

// .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
//   padding-left: 0 !important;
//   padding-right: 0 !important;
// }

.container {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1500px !important;
  }
}

@media (min-width: 1200px) {

  section.hero {
    .hero_content {
      .container {
        max-width: 1000px !important;
      }
    }
  }
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
}